require('dotenv').config()

export const UserRole = {
  Admin: "superAdmin",
  frontDesk: "frontDesk",
};

console.log("Process Env",process.env);
/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const adminRoot = '/admin';
export const frontDesk = '/fd';
export const userRoot = '/app';
export const buyUrl = 'https://teceads.com';
export const searchPath = `${adminRoot}/#`;

export const servicePath = 'https://bookings.berhampur.gov.in';
export const apiBaseUrl = 'https://bookings.berhampur.gov.in/api/';

// export const servicePath = 'http://localhost:7089';
// export const apiBaseUrl = "http://localhost:7089/api/";




export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  firstName: 'Sarah Kortney',
  lastName: 'Sarah Kortney',
  isSubscribed:true,
  userRole:UserRole.Admin,
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:23',
  // role: UserRole.Admin,
};

export const defaultUser = {
  id: 1,
  title: 'Sarah Kortney',
  firstName: 'Sarah Kortney',
  lastName: 'Sarah Kortney',
  isSubscribed:true,
  userRole:UserRole.Admin,
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:23',
  // role: UserRole.Admin,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
