import axios from 'axios';
import {apiBaseUrl,firebaseConfig} from 'constants/defaultValues';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const qs = require('qs');




const setToken = (token) => {

  console.log(token)
  if (token) {
    axios.defaults.headers.common['Authorization'] = token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }

  return 1;
};

const signInWithEmailAndPassword=(email,password)=> new Promise((resolve,reject)=>{ 
    const dataForApi = qs.stringify({
      'email': email,
      'password': password,
    });
    const config = {
      method: 'post',
      url: `${apiBaseUrl}auth/adminSignInWithEmailAndPassword`,
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : dataForApi
    };
    
    axios(config)
    .then(function (response) {



      console.log("Data",JSON.stringify(response.data));


      resolve(response.data)

    })
    .catch(function (error) {
    //   console.log(error);
      reject(error)
    });
    
});

const createUserWithEmailAndPassword=(email,password)=> new Promise((resolve,reject)=>{

    const dataForApi = qs.stringify({
      "email": email,
      "password": password,
    });
    const config = {
      method: 'post',
      url: `${apiBaseUrl}auth/createUserWithEmailAndPassword`,
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : dataForApi
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      resolve(response.data)
    })
    .catch(function (error) {
      console.log(error);
      reject(error)
    });
    
})

const createUserWithGoogle=()=> new Promise((resolve,reject)=>{
  
  resolve()
  window.location=(`${apiBaseUrl}auth/google`)

  // const config = {
  //   method: 'get',
  //   url: `${apiBaseUrl}auth/google`,
  // };
  
  // axios(config)
  // .then(function (response) {
  //   console.log(JSON.stringify(response.data));
  //   resolve(response.data)
  // })
  // .catch(function (error) {
  //   console.log(error);
  //   reject(error)
  // });
  
})
const createUserWithGoogleCallback= ()=> new Promise((resolve,reject)=>{
  const token=localStorage.getItem('token');

const data={
    success: true,
    token: localStorage.getItem('token'),
    user: {
      id: localStorage.getItem('id'),
      firstName: localStorage.getItem('fn'),
      lastName: localStorage.getItem('ln'),
      email: localStorage.getItem('em'),
      role: localStorage.getItem('rl'),
      avatar: localStorage.getItem('img')
    }
  }
  console.log(data)

  resolve(data)

// axios.get(`${apiBaseUrl}auth/google/login`)
//  .then(function (response) {
//     console.log(JSON.stringify(response.data));
//     resolve(response.data)
//   })
//   .catch(function (error) {
//     console.log(error);
//     reject(error)
//   });


  
})


const createUserWithFacebook=()=> new Promise((resolve,reject)=>{
  
  resolve()
  window.location=(`${apiBaseUrl}auth/facebook`)

  // const config = {
  //   method: 'get',
  //   url: `${apiBaseUrl}auth/Facebook`,
  // };
  
  // axios(config)
  // .then(function (response) {
  //   console.log(JSON.stringify(response.data));
  //   resolve(response.data)
  // })
  // .catch(function (error) {
  //   console.log(error);
  //   reject(error)
  // });
  
})
const createUserWithFacebookCallback= ()=> new Promise((resolve,reject)=>{
  const token=localStorage.getItem('token');

const data={
    success: true,
    token: localStorage.getItem('token'),
    user: {
      id: localStorage.getItem('id'),
      firstName: localStorage.getItem('fn'),
      lastName: localStorage.getItem('ln'),
      email: localStorage.getItem('em'),
      role: localStorage.getItem('rl'),
      avatar: localStorage.getItem('img')
    }
  }
  console.log(data)

  resolve(data)

// axios.get(`${apiBaseUrl}auth/Facebook/login`)
//  .then(function (response) {
//     console.log(JSON.stringify(response.data));
//     resolve(response.data)
//   })
//   .catch(function (error) {
//     console.log(error);
//     reject(error)
//   });


  
})




const sendPasswordResetEmail=(email)=>{

    const dataForApi = qs.stringify({
      'email': email,
    });
    const config = {
      method: 'post',
      url: `${apiBaseUrl}auth/sendPasswordResetEmail`,
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : dataForApi
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
    
}

const confirmPasswordReset=(resetPasswordCode,newPassword)=>{

    const dataForApi = qs.stringify({
      'resetPasswordCode': resetPasswordCode,
      'newPassword': newPassword,
    });
    const config = {
      method: 'post',
      url: `${apiBaseUrl}auth/confirmPasswordReset`,
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : dataForApi
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
throw error
    });
    
}

const signOut=()=>new Promise((resolve,reject)=>{

  console.log("sign out forntend")

    const dataForApi = qs.stringify({
      'signOut': "ok",
    });
    const config = {
      method: 'post',
      url: `${apiBaseUrl}/auth/signOut`,
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : dataForApi
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      resolve(response.data)
    })
    .catch(function (error) {
      console.log(error);
      reject(error)
    });
    
})



const auth={signInWithEmailAndPassword,createUserWithEmailAndPassword,sendPasswordResetEmail,confirmPasswordReset,signOut,createUserWithGoogle,createUserWithGoogleCallback,createUserWithFacebook,createUserWithFacebookCallback}

 export { auth, setToken };
